import React, { useEffect, useState, useContext } from "react";
import api from "../../api";
import Layout from "../../components/layout";
import { AuthContext } from "../../context/GlobalContextProvider";
import { LoadingOutlined } from "@ant-design/icons";
import { message, Form, Button } from "antd";
import { navigate } from "gatsby";
import SEO from "../../components/seo";
import PrediosSelect from "./components/prediosCarrossel";

export default function Inicio() {
  const [page, setPage] = useState();
  const [navbar, setNavbar] = useState();
  const context = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  useEffect(() => {
    setLoading(true);
    getPage();
    getNavbar();
  }, []);

  const getPage = async () => {
    const { data } = await api.get("/inicio");
    setPage(data);
  };

  const getNavbar = async () => {
    const { data } = await api.get("/navbar");
    setNavbar(data);
    setLoading(false);
  };

  const handleSubmit = async (values) => {
    setLoadingSave(true);
    var navbarData = new FormData();
    navbarData.append(
      "data",
      JSON.stringify({
        lancamento: values.lancamento,
        em_andamento: values.emAndamento,
        pronto: values.pronto,
      })
    );
    localStorage.removeItem("navbar");
    await api({
      method: "PUT",
      url: "/navbar",
      headers: {
        Authorization: `Bearer ${context?.token}`,
      },
      data: navbarData,
    });

    var data = new FormData();
    data.append(
      "data",
      JSON.stringify({
        predios: values.carousel,
      })
    );

    await api({
      method: "PUT",
      url: "/inicio",
      headers: {
        Authorization: `Bearer ${context?.token}`,
      },
      data,
    }).then((value) => {
      if (value) {
        message.success("Página salva com sucesso");
        navigate(`/`);
      }
    });
    setLoadingSave(false);
  };

  return (
    <Layout image="" loading={loading}>
      <SEO title="Bivver" description="Empreendimentos" />
      <Form onFinish={handleSubmit}>
        <PrediosSelect
          carrossel
          initialValue={page?.predios}
          {...{ name: "carousel", Form }}
        />
        <div className="grid lg:grid-cols-3 grid-cols-1 gap-10">
          <div>
            <h3>Lançamento</h3>
            <PrediosSelect
              initialValue={navbar?.lancamento}
              {...{ page, name: "lancamento", Form }}
            />
          </div>
          <div>
            <h3>Em andamento</h3>
            <PrediosSelect
              initialValue={navbar?.em_andamento}
              {...{ page, name: "emAndamento", Form }}
            />
          </div>
          <div>
            <h3>Pronto para morar</h3>
            <PrediosSelect
              initialValue={navbar?.pronto}
              {...{ page, name: "pronto", Form }}
            />
          </div>
        </div>
        <Form.Item>
          <Button
            className="mt-10"
            style={{ backgroundColor: "orange", color: "white" }}
            htmlType="submit"
          >
            {loadingSave && <LoadingOutlined />} Salvar
          </Button>
        </Form.Item>
      </Form>
    </Layout>
  );
}
